<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-row>
            <v-col>
                <v-form ref="orgForm" v-model="valid">
                    <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                        <v-card-title  class="headline">{{ title }}</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-text-field 
                                        :dark="$store.getters.getColorPalette().isDark" 
                                        :loading="loading"
                                        v-model="form.name"
                                        :counter="40"
                                        :rules="[rules.required]"
                                        :label="$store.getters.getTextMap().name"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <!-- <v-col>
                                    <v-text-field 
                                        :dark="$store.getters.getColorPalette().isDark" 
                                        :loading="loading"
                                        v-model="form.email"
                                        :counter="40"
                                        :rules="[rules.required,rules.email]"
                                        label="Email"
                                        required
                                    ></v-text-field>
                                </v-col> -->
                                <v-col>
                                    <v-text-field 
                                        :dark="$store.getters.getColorPalette().isDark" 
                                        :loading="loading"
                                        v-model="form.contact"
                                        :counter="10"
                                        :rules="[rules.required,rules.noSpace,rules.integ,rules.nonZeroLengthDynamic(10)]"
                                        :label="$store.getters.getTextMap().phone"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-skeleton-loader
                            v-if="loading"
                        class="mx-auto"
                        type="text"
                        ></v-skeleton-loader>
                        <v-select
                            v-if="!loading"
                            v-model="form.theme"
                            :items="$store.getters.getThemeList()"
                            :label="$store.getters.getTextMap().theme"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            
                               :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                                <v-col v-if="
        $store.state.user 
      ">
                                    <v-skeleton-loader
                            v-if="loading"
                        class="mx-auto"
                        type="text"
                        ></v-skeleton-loader>
                        <v-select
                            v-if="!loading"
                            v-model="form.lang"
                            :items="$store.getters.getLangOptions()"
                            :label="$store.getters.getTextMap().lang"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            
                               :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">{{ $store.getters.getTextMap().submit }}</v-btn>
                            <v-btn :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark"  @click="onReset">{{ $store.getters.getTextMap().cancel }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'
import FormRules from  '@/utillities/FormRules'
// import SecurityUtillity from '@/utillities/SecurityUtillity'
export default {
    name:'UpdateRootUserProfile',
    props:['user','userType'],
    components:{
        InfoAlert
    },
    data(){
        return {
            form:{
                name:null
            },
            api:'updateUser',
            title:'Update Your Profile',
            loading:false,
            info:null,
            infoMessage:"Group Created",
            showDismissibleAlert:false,
            valid:false,
            rules:FormRules.rules,
            nameRules: [
                v => !!v || this.$store.getters.getTextMap().name_is_required,
                v => /\S+/.test(v) || this.$store.getters.getTextMap().name_is_required,
                v => (v && v.length <= 10 && v.length > 0) || this.$store.getters.getTextMap().name_must_be_less_than_11_characters,
            ],
            msgRules: [
                v => !!v || this.$store.getters.getTextMap().description_is_required,
                v => (v && v.length <= 35 && v.length > 0) || this.$store.getters.getTextMap().description_must_be_less_than_35_characters,
            ],
        }
    },
    mounted(){
      if(this.user && this.userType){
        this.api='updateUserProfile'
        this.infoMessage="Your Profile has been Updated Successfuly"
        this.form = Object.assign({},this.user)
        // this.form.accessList=SecurityUtillity.getAccessListByUserLevel(this.user.user_level)
      }
    },
    methods:{
        onSubmit(){
            this.$refs.orgForm.validate()
            if(this.valid){
                this.loading = true;
                this.form.type=this.userType
                axios.post(this.$store.state.api + this.api, this.form,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                }}).then(response => {
                    if (response.data.status == "success") {
                        // this.$store.dispatch("refreshUAMUsers")
                        this.$store.dispatch("refreshUserData")
                        this.info = this.infoMessage;
                        this.showDismissibleAlert = true;
                        this.loading = false;
                        this.onReset()
                        this.$emit('close')
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                }).catch(error => {
                    this.onReset()
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
            }
        },
        onReset(){
            this.$emit('close')
        }
    }
}
</script>
<style>

</style>