<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row v-if="userData">
            <v-col>
                <UpdateRootProfileBottomeSheet :user="userData" :userType="'root'" v-on:close="getUserData"/>
            </v-col>
            <v-col align-self="center" v-if="$store.state.user">
                <ChangePasswordModal/>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col>
                <v-card class="pa-3" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode" >
                    <v-row>
                        <v-col >
                            <v-row>
                                <v-col>
                                    <v-card flat :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
                                        <v-card-title >Name <v-icon class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtextColor">mdi-account</v-icon></v-card-title>
                                        <v-card-text :dark="$store.getters.getColorPalette().isDark" >
        
                                            {{ userData&&userData.name?userData.name:'-' }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-card flat :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
                                        <v-card-title>Email <v-icon class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtextColor">mdi-email</v-icon></v-card-title>
                                        <v-card-text :dark="$store.getters.getColorPalette().isDark" >
        
                                            {{ userData&&userData.email?userData.email:'NA' }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-card flat :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
                                        <v-card-title> Phone Number<v-icon class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtextColor">mdi-phone</v-icon></v-card-title>
                                        <v-card-text :dark="$store.getters.getColorPalette().isDark" >
        
                                            {{ userData&&userData.contact?userData.contact:'NA' }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>

                            <v-col>
                                <v-card flat :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
                                    <v-card-title>Language <v-icon class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtextColor">mdi-palette</v-icon></v-card-title>
                                    <v-card-text :dark="$store.getters.getColorPalette().isDark" >
                                        
                                        {{userData&&userData.lang?userData.lang:'Not Set' }}
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col>
                                    <v-card flat :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
                                        <v-card-title>Organisation<v-icon class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtextColor">mdi-office-building</v-icon></v-card-title>
                                        <v-card-text :dark="$store.getters.getColorPalette().isDark" >
        
                                            {{$store.getters.getOrganisationName()}}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-card flat :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
                                        <v-card-title>ID <v-icon class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtextColor">mdi-pound</v-icon></v-card-title>
                                        <v-card-text :dark="$store.getters.getColorPalette().isDark" >
        
                                            {{$store.state.user?$store.state.user.organisation_id:'-' }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-card flat :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
                                        <v-card-title>Theme <v-icon class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtextColor">mdi-palette</v-icon></v-card-title>
                                        <v-card-text :dark="$store.getters.getColorPalette().isDark" >
        
                                            {{userData&&userData.theme?userData.theme:'Not Set' }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                        </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import UpdateRootProfileBottomeSheet from '@/components/modals/UpdateRootProfileBottomeSheet'
import ChangePasswordModal from '@/components/modals/ChangePasswordModal'
import axios from 'axios'
export default {
    name:'RootProfile',
    components:{
        InfoAlert,
        UpdateRootProfileBottomeSheet,
        ChangePasswordModal
    },
    data(){
        return {
            showDismissibleAlert:false,
            loading:false,
            info:'',
            userData:null
        }
    },
    mounted(){
        this.getUserData()
    },
    methods:{
        getUserData(){
            
            axios.get(this.$store.state.api+'getUserDetails',{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
             }}).then(response=>{
                    if(response.data.status==='success'){
                        this.userData=response.data.data

                    this.loading=false
                    }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
            }}).catch(err=>{
                console.log(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
            })
        }
    }
}
</script>
<style>

</style>