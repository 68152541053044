<template>
<div>
  <v-btn
  @click="dialog=!dialog"
      :dark="$store.getters.getColorPalette().isDark"
  text
   small
   outlined
  :background-color="$store.getters.getColorPalette().background1ColorCode"
 :color="$store.getters.getColorPalette().accentCode"
  >
                
                 {{ $store.getters.getTextMap().update_password }}
                 <v-icon  :color="$store.getters.getColorPalette().downloadbtnColor">
                    mdi-human-male
                </v-icon> 
            </v-btn>
    <v-dialog
      v-model="dialog"
      
      max-width="600"
    >
      <template>
      <!-- <template v-slot:activator="{ on, attrs }"> -->
        
      </template>
      <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
          <ChangePassword v-on:close="onClose" />
      </v-card>
      
    </v-dialog>
</div>
</template>

<script>
import ChangePassword from '@/components/account_components/ChangePassword'
  export default {
      name:'ChangePasswordModal',
      components:{
          ChangePassword
      },
    data () {
      return {
        dialog: false
      }
    },
    methods:{
        onClose(){
            this.dialog=false
            this.$emit('close')
            }
        }

    }
</script>

<style scoped>
.button_pass{
  margin-right: 30px;
 
}
/* .theme--light.v-card{
  background-color:#263238;
} */
</style>