<template>
<div>
        <v-btn
            @click="openDialog"
            :background-color="$store.getters.getColorPalette().background1ColorCode"
            :color="$store.getters.getColorPalette().accentCode"
            :dark="$store.getters.getColorPalette().isDark"
            outlined
            small
            text
        >
        {{$store.getters.getTextMap().update_profile}}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor">mdi-pencil</v-icon>
        </v-btn>
        <v-dialog v-model="dialog" max-width="1000">
          <v-card :dark="$store.getters.getColorPalette().isDark">
              <UpdateUAMUserProfile :user="user" :userType="userType" v-on:close="onClose" />
          </v-card>
          
        </v-dialog>
</div>
</template>

<script>
import UpdateUAMUserProfile from '@/components/account_components/UpdateUAMUserProfile'
  export default {
      name:'UpdateUAMUserProfileBottomSheet',
      props:['user','userType'],
      components:{
          UpdateUAMUserProfile
      },
    data () {
      return {
        dialog: false
      }
    },
    methods:{
        openDialog(){
            if(this.user && this.userType){
                this.dialog=!this.dialog
            }
        },
        onClose(){
            this.dialog=false
            this.$emit('close')
            }
        }

    }
</script>
<style scoped>
.uam{
  border:3px solid;
  border-radius:20px;
}
</style>
