<template>
    <v-container fluid>
        <v-row  v-if="$store.state.user && $store.state.user.user_type==='uam'">
            <v-col cols="12">
                <UAMProfile />
            </v-col>
        </v-row>
        <v-row  v-else>
            <v-col cols="12">
                <RootProfile />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import UAMProfile from '@/pages/UAMProfile'
import RootProfile from '@/pages/RootProfile'

export default {
    name:'User',
    data(){
        return {}
    },
    components:{
        UAMProfile,
        RootProfile,
    },
    mounted() {
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    },
}
</script>
<style scoped>
.container{
    margin-top: -1%;
}
</style>