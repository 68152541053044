<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row v-if="userData">
            <v-col cols="auto" class="d-flex mt-1"  >
                <UpdateUAMProfileBottomeSheet class="mr-1" :user="userData" :userType="'uam'" v-on:close="getUserData"/>
                <ChangePasswordModal/>
            </v-col>
            <!-- <v-col>
            </v-col>
            <v-col align-self="center" v-if="$store.state.user">
            </v-col> -->
        </v-row>
        <v-row>
            <v-col>
                <v-card class="pa-3" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode" >
                    <v-row>
                        <v-col >
                            <v-row>
                                <v-col>
                                    <v-card flat :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
                                        <v-card-title >{{ $store.getters.getTextMap().name  }} <v-icon class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtextColor">mdi-account</v-icon></v-card-title>
                                        <v-card-text :dark="$store.getters.getColorPalette().isDark" >
        
                                            {{ userData&&userData.name?userData.name:$store.state.user.name }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-card flat :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
                                        <v-card-title>{{ $store.getters.getTextMap().email }} <v-icon class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtextColor">mdi-email</v-icon></v-card-title>
                                        <v-card-text :dark="$store.getters.getColorPalette().isDark" >
        
                                            {{ userData&&userData.email?userData.email:'NA' }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-card flat :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
                                        <v-card-title> {{ $store.getters.getTextMap().phone }}<v-icon class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtextColor">mdi-phone</v-icon></v-card-title>
                                        <v-card-text :dark="$store.getters.getColorPalette().isDark" >
        
                                            {{ userData&&userData.phone?userData.phone:'NA' }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>

                                <v-col>
                                    <v-card flat :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
                                        <v-card-title>{{ $store.getters.getTextMap().language }} <v-icon class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtextColor">mdi-palette</v-icon></v-card-title>
                                        <v-card-text :dark="$store.getters.getColorPalette().isDark" >
                                            
                                            {{userData&&userData.lang?userData.lang:'Not Set' }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                </v-row>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col>
                                    <v-card flat :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
                                        <v-card-title>{{ $store.getters.getTextMap().organisation }}<v-icon class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtextColor">mdi-office-building</v-icon></v-card-title>
                                        <v-card-text :dark="$store.getters.getColorPalette().isDark" >
        
                                            {{userData&&userData.organisation_name?userData.organisation_name:'NA'}}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-card flat :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
                                        <v-card-title>{{ $store.getters.getTextMap().organisation_id }} <v-icon class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtextColor">mdi-pound</v-icon></v-card-title>
                                        <v-card-text :dark="$store.getters.getColorPalette().isDark" >
        
                                            {{userData&&userData.organisation_id?userData.organisation_id:'NA' }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-card flat :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}">
                                        <v-card-title>{{ $store.getters.getTextMap().theme }} <v-icon class="ml-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtextColor">mdi-palette</v-icon></v-card-title>
                                        <v-card-text :dark="$store.getters.getColorPalette().isDark" >
        
                                            {{$store.state.user.theme?$store.state.user.theme:'Not Configured' }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import UpdateUAMProfileBottomeSheet from '@/components/modals/UpdateUAMProfileBottomSheet'
import ChangePasswordModal from '@/components/modals/ChangePasswordModal'
export default {
    name:'UAMProfile',
    components:{
        InfoAlert,
        UpdateUAMProfileBottomeSheet,
        ChangePasswordModal
    },
    data(){
        return {
            showDismissibleAlert:false,
            loading:false,
            info:'',
            userData:null
        }
    },
    mounted(){
        this.getUserData()
    },
    methods:{
        getUserData(){
            
            axios.get(this.$store.state.api+'getUserDetails',{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
             }}).then(response=>{
                    if(response.data.status==='success'){
                        this.userData=response.data.data
                    this.loading=false
                    }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
            }}).catch(err=>{
                console.log(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
            })
        }
    }
}
</script>
<style>

</style>